

import React, { useState } from "react";
import {
  Typography,
  Dialog,
  DialogContent,
  Button,
  Box,
  IconButton,
  DialogContentText,
  DialogTitle,
  Grid,
  DialogActions,
} from "@material-ui/core";

import { IoClose } from "react-icons/io5";

export default function DisconnectModal({ user, open, onClose }) {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={"xs"}>
      <IconButton
       onClick={onClose}
        style={{ position: "absolute", right: "0" }}
      >
        <IoClose style={{ color: "rgba(255, 255, 255, 0.60)" }} />
      </IconButton>
      <DialogTitle id="alert-dialog-title" style={{ padding: "16px 18px 0px" }}>
        <Typography variant="h5" style={{ color: "#FFF", marginTop: "20px" }}>
        Disconnect Wallet
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Typography
            variant="body2"
            style={{
              marginBottom: "25px",
              color: "rgba(255, 255, 255, 0.60)",
              fontSize: "12px",
            }}
          >
            Are you sure want to disconnect your wallet.
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box className="connectwalletBox displayCenter">
                <img src="/images/mask_1.png" alt="Metamask" />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className="connectwalletBox displayCenter">
                <img src="/images/banner/trustwaller.png" alt="Trust Wallet" />
              </Box>
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Box mb={4} mt={1} style={{ width: "100%" }}>
          <Button
           onClick={() => user.disconnectWallet()}

          //  onClick={() => {
          //   if (account) {
          //     user.disconnectWallet();
          //     localStorage.removeItem("walletName");
          //   } 
          // }}
            color="primary"
            variant="contained"
            fullWidth
          >
            Disconnect
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
