import React, { useContext } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  makeStyles,
  IconButton,
  Typography,
  SvgIcon,
  Toolbar,
  AppBar,
  Hidden,
  Avatar,
  Grid,
  Box,
} from "@material-ui/core";
import { Menu as MenuIcon } from "react-feather";
import { Link, useLocation, useHistory } from "react-router-dom";
import { UserContext } from "src/context/User";
import Logo from "src/component/Logo";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    padding: "0 10px",
    paddingRight: "15px",
    background: "#161032",
    [theme.breakpoints.down("sm")]: {
      paddingRight: "15px",
    },
  },
  logo: {
    marginRight: theme.spacing(2),
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    "& + &": {
      marginLeft: theme.spacing(2),
    },
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  mainheader: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    paddingTop: "8px",
    padding: "8px",
    "& .leftBox": {
      width: "306px",
      [theme.breakpoints.down("md")]: {
        width: "200px",
      },
      [theme.breakpoints.down("xs")]: {
        width: "150px",
      },
      "& img": {
        width: "80px",
        [theme.breakpoints.down("xs")]: {
          paddingLeft: "0 !important",
        },
      },
    },
  },
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();
  const location = useLocation();

  return (
    <AppBar
      elevation={0}
      className={clsx(classes.root, className)}
      color="inherit"
      style={{ boxShadow: "0px 4px 4px rgb(0 0 0 / 10%)" }}
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton color="#00e0b0" onClick={onMobileNavOpen}>
            <SvgIcon fontSize="large">
              <MenuIcon
                style={{ color: "rgb(168, 27, 45)", fontSize: "45px" }}
              />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Box className={classes.mainheader}>
          <Grid container alignItems="center">
            <Grid item xs={6}>
              <Box className="leftBox">
                <Link to="/">
                  <Logo width="125" />
                </Link>
              </Box>
            </Grid>
            {/* <Grid item xs={6} align="right">
              <TopBarData />
            </Grid> */}
          </Grid>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};
TopBar.defaultProps = {
  onMobileNavOpen: () => {},
};

export default TopBar;

export function TopBarData() {
  const classes = useStyles();
  const history = useHistory();
  const auth = useContext(UserContext);

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="flex-end">
        <Hidden xsDown>
          <Box>
            <Typography variant="h5">NFT Marketplace</Typography>
            <Typography variant="body1" style={{ color: "#ffffff9c" }}>
              example@gmail.com
            </Typography>
          </Box>
        </Hidden>
        &nbsp; &nbsp;
        <Avatar
          src={
            auth?.userData?.profilePic
              ? `${auth?.userData?.profilePic}`
              : "https://picsum.photos/533/357"
          }
          className={classes.avatar}
          // onClick={() => history.push("/admin-profile")}
        />
      </Box>
    </>
  );
}
