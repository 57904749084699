import React from "react";
import { Box ,makeStyles}from "@material-ui/core";
import TopBar from "./TopBar";

const useStyles = makeStyles((theme) => ({
  MainComponent:{
    "& .loginLayoutBox": {
      background: "#161032",
      width: "100%",
      "& .contentContainer1": {
        height: "100vh",
        position: "relative",
      },
      "& .MainLayout": {
        height: "100vh",
        position: "relative",
      }, 
    },
  }
 
}));

export default function LoginLayout({ children }) {
  const classes = useStyles()
  return (
    <Box className={classes.MainComponent}>
      <TopBar />
      <Box className="loginLayoutBox">
        <Box className="contentContainer1">
          <Box className="MainLayout">{children}</Box>
        </Box>
      </Box>
    </Box>
  );
}
