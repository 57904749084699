export const ACTIVE_API_URL = process.env.REACT_APP_API_TESTNET;

//---#ACTIVE-CHAIN-INFO#---//
export const NetworkContextName = "Polygon Amoy";
export const ACTIVE_NETWORK = 80002;
export const RPC_URL = "https://polygon-amoy.drpc.org";

export const NFTMintingMumbai = "0xac7e1cDC99140ACa96998D451aaA200a90157947"; //testnet --------------- single -----
export const NFTMultiMumbai = "0x15A28Da4c8f6D99434de3C6C4d5e97d02A1e2C1D"; //testnet  ---------------cashback for 1155 make this similar
export const NFTAuctionMumbai = "0xe35c9fDE0f1e79d5Ba60CCf0fB9D8906EA8f860e"; //testnet new ----------- Auction
export const NFTListingMumbai = "0xb16878045f120147e6edA7C24D17c95AE5345e6a"; //testnet ---------------  marketplace
 
export const zeroAddress = "0x0000000000000000000000000000000000000000";

//---#ACTIVE-NETWORK-DETAILS#---//
export const NetworkDetails = [
  {
    chainId: "0x13882",
    chainName: "Amoy",
    nativeCurrency: {
      name: "MATIC",
      symbol: "MATIC",
      decimals: 18,
    },
    rpcUrls: [RPC_URL],
    blockExplorerUrls: ["https://www.oklink.com/amoy/"],
  },
];

//---#APPLICATION-EXPLICIT-DATA#---//
export const CategoryButtons = [
  {
    name: "Hot & Top Sellers",
  },
  {
    name: "Gaming Items",
  },
  {
    name: "Metaverse",
  },
  {
    name: "Art",
  },
  {
    name: "Videos",
  },
  {
    name: "Letter of wishes",
  },
  {
    name: "Web 3.0",
  },
];
export const RankingButtons = [
  {
    name: "Last 10 Days",
  },
  {
    name: "Last 30 Days",
  },
  {
    name: "Last 10 Days",
  },
  {
    name: "Last 20 Days",
  },
  {
    name: "Last 7 Days",
  },
  {
    name: "Last 15 Days",
  },
  {
    name: "Last 30 Days",
  },
];

export const collectionData = [
  {
    image: "images/Collections/Collection1.png",
    img: "images/Sellers/Sellers1.png",
    name: "Sophia Valentine",
    price: "0.99  ETH",
  },
  {
    image: "images/Collections/Collection2.png",
    img: "images/Sellers/Sellers2.png",
    name: "The Metakey",
    price: "0.43  ETH",
  },
  {
    image: "images/Collections/Collection3.png",
    img: "images/Sellers/Sellers3.png",
    name: "Diamond HODLR",
    price: "4.73  ETH",
  },
  {
    image: "images/Collections/Collection4.png",
    img: "images/Sellers/Sellers4.png",
    name: "Sophia Valentine",
    price: "5.22  ETH",
  },
  {
    image: "images/Collections/Collection5.png",
    img: "images/Sellers/Sellers1.png",
    name: "Sophia Valentine",
    price: "0.99  ETH",
  },
  {
    image: "images/Collections/Collection1.png",
    img: "images/Sellers/Sellers2.png",
    name: "The Metakey",
    price: "0.43  ETH",
  },
  {
    image: "images/Collections/Collection2.png",
    img: "images/Sellers/Sellers3.png",
    name: "Diamond HODLR",
    price: "4.73  ETH",
  },
  {
    image: "images/Collections/Collection3.png",
    img: "images/Sellers/Sellers4.png",
    name: "Sophia Valentine",
    price: "5.22  ETH",
  },
];

export const exploreData = [
  {
    user: "@Alex",
    likes: "152",
    time: "8h : 15m : 25s left",
    stock: "4 in stock",
    text3: "From 1.35 ETH 11/Bid 1.1 w",
    image: "images/Explore/Explore1.png",
    name: "Skyblue Creator",
    price: "0.004 ETH",
  },
  {
    user: "@Alex",
    likes: "152",
    time: "8h : 15m : 25s left",
    stock: "4 in stock",
    text3: "From 1.35 ETH 11/Bid 1.1 w",
    image: "images/Explore/Explore2.png",
    name: "Skyblue Creator",
    price: "0.004 ETH",
  },
  {
    user: "@Alex",
    likes: "152",
    time: "8h : 15m : 25s left",
    stock: "4 in stock",
    text3: "From 1.35 ETH 11/Bid 1.1 w",
    image: "images/Explore/Explore3.png",
    name: "Skyblue Creator",
    price: "0.004 ETH",
  },
  {
    user: "@Alex",
    likes: "152",
    time: "8h : 15m : 25s left",
    stock: "4 in stock",
    text3: "From 1.35 ETH 11/Bid 1.1 w",
    image: "images/Explore/Explore4.png",
    name: "Skyblue Creator",
    price: "0.004 ETH",
  },
  {
    user: "@Alex",
    likes: "152",
    time: "8h : 15m : 25s left",
    stock: "4 in stock",
    text3: "From 1.35 ETH 11/Bid 1.1 w",
    image: "images/Explore/Explore1.png",
    name: "Skyblue Creator",
    price: "0.004 ETH",
  },
  {
    user: "@Alex",
    likes: "152",
    time: "8h : 15m : 25s left",
    stock: "4 in stock",
    text3: "From 1.35 ETH 11/Bid 1.1 w",
    image: "images/Explore/Explore2.png",
    name: "Skyblue Creator",
    price: "0.004 ETH",
  },
  {
    user: "@Alex",
    likes: "152",
    time: "8h : 15m : 25s left",
    stock: "4 in stock",
    text3: "From 1.35 ETH 11/Bid 1.1 w",
    image: "images/Explore/Explore3.png",
    name: "Skyblue Creator",
    price: "0.004 ETH",
  },
  {
    user: "@Alex",
    likes: "152",
    time: "8h : 15m : 25s left",
    stock: "4 in stock",
    text3: "From 1.35 ETH 11/Bid 1.1 w",
    image: "images/Explore/Explore4.png",
    name: "Skyblue Creator",
    price: "0.004 ETH",
  },
];

export const tableData = [
  {
    image: "images/Profile.png",
    name: "Abcd Efght",
    text1: " 133,871.43",
    text2: "-48.31%",
    text3: "+1396.90%",
    text4: "133",
    text6: "3.2 K",
    text5: "10.0K",
  },
  {
    image: "images/Profile.png",
    name: "Abcd Efght",
    text1: " 133,871.43",
    text2: "-48.31%",
    text3: "+1396.90%",
    text4: "133",
    text5: "10.0K",
    text6: "3.2 K",
  },
  {
    image: "images/Profile.png",
    name: "Abcd Efght",
    text1: " 133,871.43",
    text2: "-48.31%",
    text3: "+1396.90%",
    text4: "133",
    text5: "10.0K",
    text6: "3.2 K",
  },
  {
    image: "images/Profile.png",
    name: "Abcd Efght",
    text1: " 133,871.43",
    text2: "-48.31%",
    text3: "+1396.90%",
    text4: "133",
    text5: "10.0K",
    text6: "3.2 K",
  },
  {
    image: "images/Profile.png",
    name: "Abcd Efght",
    text1: " 133,871.43",
    text2: "-48.31%",
    text3: "+1396.90%",
    text4: "133",
    text5: "10.0K",
    text6: "3.2 K",
  },
  {
    image: "images/Profile.png",
    name: "Abcd Efght",
    text1: " 133,871.43",
    text2: "-48.31%",
    text3: "+1396.90%",
    text4: "133",
    text5: "10.0K",
    text6: "3.2 K",
  },
  {
    image: "images/Profile.png",
    name: "Abcd Efght",
    text1: " 133,871.43",
    text2: "-48.31%",
    text3: "+1396.90%",
    text4: "133",
    text5: "10.0K",
    text6: "3.2 K",
  },
  {
    image: "images/Profile.png",
    name: "Abcd Efght",
    text1: " 133,871.43",
    text2: "-48.31%",
    text3: "+1396.90%",
    text4: "133",
    text5: "10.0K",
    text6: "3.2 K",
  },
  {
    image: "images/Profile.png",
    name: "Abcd Efght",
    text1: " 133,871.43",
    text2: "-48.31%",
    text3: "+1396.90%",
    text4: "133",
    text5: "10.0K",
    text6: "3.2 K",
  },
];
