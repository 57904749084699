// import React, { useState } from "react";
// import {
//   makeStyles,
//   Dialog,
//   DialogContent,
//   IconButton,
//   Typography,
//   Box,
//   Grid,
//   Hidden,
// } from "@material-ui/core";
// import { IoClose } from "react-icons/io5";
// import { SUPPORTED_WALLETS } from "src/connectors";
// const useStyles = makeStyles((theme) => ({
//   connectModalBox: {
//     padding: "30px",
//     [theme.breakpoints.down("xs")]: {
//       padding: "10px",
//     },
//     "& .imageBox": {
//       position: "absolute",
//       bottom: "30px",
//       left: "114px",
//       maxWidth: "360px",
//     },
//     "& .eagleBirdBox": {
//       position: "absolute",
//       top: "25px",
//       left: "-25px",
//     },
//     "& h4": {
//       color: "#fff",
//       fontWeight: "300",
//     },
//     "& h6": {
//       color: "rgba(255, 255, 255, 0.6)",
//       fontWeight: "300",
//       marginTop: "12px",
//     },
//     "& p": {
//       color: "red",
//     },
//     "& .gridFlex": {
//       padding: "30px 27px",
//       [theme.breakpoints.down("sm")]: {
//         display: "flex",
//         flexDirection: "column",
//         alignItems: "center",
//       },
//     },
//   },
//   walletBox: {
//     display: "flex",
//     justifyContent: "space-between",
//     "& .Wallet": {
//       background: "rgb(21 20 20)",
//       padding: "31px 24px",
//       borderRadius: "14px",
//       // minWidth: "113px",
//       marginRight: "9px",
//       marginTop: "24px",
//       border: "1px solid #2c2c2c",
//       cursor: "pointer",
//       "& h5": {
//         marginTop: "16px",
//         color: "#fff",
//         whiteSpace: "pre",
//         [theme.breakpoints.down("xs")]: {
//           fontSize: "14px !important",
//           marginTop: "6px",
//         },
//       },
//       [theme.breakpoints.down("xs")]: {
//         padding: "20px",
//         marginLeft: "5px",
//       },
//       "& img": {
//         [theme.breakpoints.down("xs")]: {
//           maxWidth: "35px",
//           width: "auto",
//         },
//       },
//     },
//   },
//   cancelBtn: {
//     position: "absolute",
//     top: 0,
//     right: 0,
//     "& svg": {
//       color: "#fff",
//       fontWeight: "700",
//     },
//   },
// }));

// function ConnectModal({ user, open, setOpen, account }) {
//   const classes = useStyles();
//   return (
//     <>
//       <Dialog
//         open={open}
//         onClose={() => setOpen()}
//         aria-labelledby="alert-dialog-title"
//         aria-describedby="alert-dialog-description"
//         maxWidth="md"
//         fullWidth
//       >
//         <DialogContent className="borderShadowBox">
//           <Box className={classes.connectModalBox}>
//             <Grid container spacing={5}>
//               <Hidden smDown>
//                 <Grid item xs={6} sm={6} md={6}>
//                   <Box className="circleBoxSmall"></Box>
//                 </Grid>
//               </Hidden>

//               <Grid item xs={12} sm={12} md={6}>
//                 <Box className="gridFlex">
//                   <Box>
//                     <Typography className="ubuntu" variant="h4">
//                       Connect your Wallet
//                     </Typography>
//                   </Box>
//                   <Box>
//                     <Typography className="ubuntu" variant="h6">
//                       Choose your wallet, that you want to connect.
//                     </Typography>
//                   </Box>
//                   <Box className={classes.walletBox}>
//                     {SUPPORTED_WALLETS.map((item, i) => {
//                       return (
//                         <Box
//                           align="center"
//                           className="Wallet"
//                           key={i}
//                           onClick={() => {
//                             if (account) {
//                               user.disconnectWallet();
//                               localStorage.removeItem("walletName");
//                             } else {
//                               user.connectWallet(item.data);
//                             }
//                           }}
//                         >
//                           <img width="45px" src={item.data.iconName} />
//                           <Typography className="ubuntu" variant="h5">
//                             {item.data.name}
//                           </Typography>
//                         </Box>
//                       );
//                     })}

//                     {/* <Box align="center" className="Wallet">
//                       <img width="57px" src="images/WayConnectImg.png" />
//                       <Typography className="ubuntu" variant="h5">
//                         Way Connect
//                       </Typography>
//                     </Box> */}
//                   </Box>
//                   {/* <Box mt={4}>
//                     <Typography className="ubuntu" variant="body2">
//                       Wallet is Wallet?
//                     </Typography>
//                   </Box>*/}
//                 </Box>
//               </Grid>
//             </Grid>
//             <Hidden smDown>
//               <Box className="imageBox">
//                 <img width="100%" src="images/home.png" />
//               </Box>
//               <Box className="eagleBirdBox">
//                 <img src="images/eagleBird.png" />
//               </Box>
//             </Hidden>

//             <IconButton className={classes.cancelBtn} onClick={() => setOpen()}>
//               <IoClose />
//             </IconButton>
//           </Box>
//         </DialogContent>
//       </Dialog>
//     </>
//   );
// }

// export default ConnectModal;

import React, { useState } from "react";
import {
  Typography,
  Dialog,
  DialogContent,
  Button,
  Box,
  IconButton,
  DialogContentText,
  DialogTitle,
  Grid,
  DialogActions,
} from "@material-ui/core";
import { SUPPORTED_WALLETS } from "src/connectors";

import { IoClose } from "react-icons/io5";

export default function ConnectModal({ user, open, setOpen, account }) {
  return (
    <Dialog open={open} onClose={() => setOpen()} fullWidth maxWidth={"xs"}>
      <IconButton
        onClick={() => setOpen()}
        style={{ position: "absolute", right: "0" }}
      >
        <IoClose style={{ color: "rgba(255, 255, 255, 0.60)" }} />
      </IconButton>
      <DialogTitle id="alert-dialog-title" style={{ padding: "16px 18px 0px" }}>
        <Typography variant="h5" style={{ color: "#FFF", marginTop: "20px" }}>
          Connect your Wallet
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Typography
            variant="body2"
            style={{
              marginBottom: "25px",
              color: "rgba(255, 255, 255, 0.60)",
              fontSize: "12px",
            }}
          >
            Please connect a wallet to continue your purchase
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Box mb={4} mt={1} style={{ width: "100%" }}>
          <Grid container spacing={2}>
            {SUPPORTED_WALLETS.map((item, i) => {
              return (
                <Grid item xs={12} md={6}>
                  <Box
                    className="connectwalletBox displayCenter"
                    key={i}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      if (account) {
                        user.disconnectWallet();
                        localStorage.removeItem("walletName");
                      } else {
                        user.connectWallet(item.data);
                      }
                    }}
                  >
                    <img src={item.data.iconName} alt="Trust Wallet" />
                  </Box>
                </Grid>
              );
            })}
          </Grid>
          {/* <Box mt={2}>
            <Button
              onClick={() => user.connectWallet()}
              color="primary"
              variant="contained"
              fullWidth
            >
              Connect
            </Button>
          </Box> */}
        </Box>
      </DialogActions>
    </Dialog>
  );
}
