import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import HomeLayout from "src/layouts/HomeLayout";
import DashboardLayout from "src/layouts/DashboardLayout";
import LoginLayout from "./layouts/LoginLayout";

export const routes = [
  // {
  //   exact: true,
  //   path: "/home",
  //   layout: HomeLayout,
  //   component: lazy(() => import("src/views/pages/Home")),
  // },

  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Landing")),
  },
  {
    exact: true,
    path: "/login",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/auth/Login")),
  },
  {
    exact: true,
    path: "/signup",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/auth/Signup")),
  },
  {
    exact: true,
    path: "/verify-otp",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/auth/VerifyOtp")),
  },
  {
    exact: true,
    path: "/reset-password",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/auth/ResetPassword")),
  },
  {
    exact: true,
    path: "/forgot-password",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/auth/ForgotPassword")),
  },
  {
    exact: true,
    path: "/brands",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Wineries/index")),
  },
  
  {
    exact: true,
    path: "/about-us",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/About/About")),
  },
  {
    exact: true,
    path: "/single-nft",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/SingleNFTpage/Singlenft")),
  },
  {
    exact: true,
    path: "/artist-profile",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Artist/ArtistProfile")),
  },
  {
    exact: true,
    path: "/artist-detail",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Artist/AlbumDetail")),
  },

  {
    exact: true,
    path: "/creater-list",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Creater/Creaters")),
  },

  {
    exact: true,
    path: "/nft-type",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/NftTypePage/NftBanner")),
  },

  {
    exact: true,

    path: "/author-profile",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/AuthorProfile/AuthorMain")),
  },
  {
    // exact: true,
    path: "/other-profile",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/OtherProfiles/OtherProfiles")
    ),
  },
  {
    exact: true,
    path: "/terms-conditions",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/TermsAndPrivacy/TermsConditions")
    ),
  },
  {
    exact: true,
    path: "/privacy-policy",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/TermsAndPrivacy/PrivacyPolicy")
    ),
  },
  {
    exact: true,
    path: "/faq",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/StaticPages/Faq")
    ),
  },
  {
    exact: true,
    path: "/album-loader",
    layout: HomeLayout,
    component: lazy(() => import("src/skeletonLoaders/AlbumLoder")),
  },

  {
    exact: true,
    path: "/edit-profile",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/AuthorProfile/EditProfilePage")
    ),
  },
  {
    exact: true,
    path: "/update-profile",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/AuthorProfile/UpdateProfile")
    ),
  },
  {
    exact: true,
    path: "/albums",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Albums/index")),
  },
  {
    exact: true,
    path: "/marketplace",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/MarketPlace")),
  },
  {
    exact: true,
    path: "/search-data",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/SearchNft/index")),
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    exact: true,
    path: "/lets-connect",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/ContactUs/LetsConnect")),
  },
  {
    exact: true,
    path: "/connect-creator",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/ContactUs/FormApplyForCreater")
    ),
  },

  {
    exact: true,
    path: "/Experience",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Experience")),
  },
  {
    exact: true,
    path: "/redeem",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Redeem/index")),
  },
  {
    exact: true,
    path: "/add-checkout",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Redeem/AddCheckoutAddress")),
  },
  
  // --------------Admin---------------
  {
    exact: true,
    guard: true,
    path: "/transaction-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/AdminSection/TransactionManagement/TransactionList"
      )
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/nft-list",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/CreateNFT/NftData"
      )
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/create-nft",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/CreateNFT/Create")),
  },
  {
    exact: true,
    guard: true,
    path: "/vendor-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/AdminSection/VendorManagement/index"
      )
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/report-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/AdminSection/ReportManagement/index"
      )
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/notified-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/AdminSection/NotifiedManagement/index"
      )
    ),
  },
  
  {
    exact: true,
    guard: true,
    path: "/add-vendor",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/AdminSection/VendorManagement/AddVendor"
      )
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/brand-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/AdminSection/BrandManagement/index"
      )
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/add-brand",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/AdminSection/BrandManagement/AddBrand"
      )
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/view-transaction",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/AdminSection/TransactionManagement/ViewTransaction"
      )
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/category-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/CategoryManagement/CategoryList")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/edit-category",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/CategoryManagement/EditCategory")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/mood-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/MoodManagement/MoodList")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/edit-mood",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/MoodManagement/EditMood")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/user-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/UserManagement/UserData")
    ),
  },

  {
    exact: true,
    guard: true,
    path: "/view-user",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/UserManagement/ViewUser")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/admin-control",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/Control/AdminControl")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/static-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/StaticManagement/StaticData")
    ),
  },
  {
    exact: true,
    path: "/edit-static",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/StaticManagement/EditStaticData")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/terms-conditions-admin",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/StaticPages/TermAndConditions")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/privacy-policy-admin",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/StaticPages/PrivacyAndPolicy")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/faq-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/FaqManagement/UpdateFaq")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/about-mbit-admin",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/StaticManagement/AboutMbit")
    ),
  },
  {
    exact: true,
    layout: DashboardLayout,
    guard: true,
    path: "/faq-add",
    component: lazy(() =>
      import("src/views/pages/AdminSection/FaqManagement/AddFaq")
    ),
  },
  {
    exact: true,
    layout: DashboardLayout,
    path: "/faq-list",
    component: lazy(() =>
      import("src/views/pages/AdminSection/FaqManagement/Faq")
    ),
  },

  {
    exact: true,
    guard: true,
    layout: DashboardLayout,
    path: "/editfaq-list",
    component: lazy(() =>
      import("src/views/pages/AdminSection/FaqManagement/EditFaq")
    ),
  },
  {
    exact: true,
    guard: true,
    layout: DashboardLayout,
    path: "/view-faqdata",
    component: lazy(() =>
      import("src/views/pages/AdminSection/FaqManagement/ViewFaq")
    ),
  },

  {
    component: () => <Redirect to="/404" />,
  },
];
