/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from "react";
import { useLocation, matchPath, useHistory } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import ConfirmationDialog from "src/component/ConfirmationDialog";
import { FaUserAlt } from "react-icons/fa";
import PropTypes from "prop-types";
import {
  Box,
  Drawer,
  Hidden,
  List,
  Button,
  ListSubheader,
  makeStyles,
  Typography,
  Divider,
} from "@material-ui/core";

import NavItem from "./NavItem";
import { MdDescription } from "react-icons/md";
const sections = [
  {
    items: [
      {
        title: "Transaction Management",
        icon: "/images/dashboard/menu.png",
        href: "/transaction-management",
      },
      // {
      //   title: "Category Management",
      //   icon: "/images/dashboard/management.png",
      //   href: "/category-management",
      // },
      // {
      //   title: "Mood Management",
      //   icon: "/images/dashboard/vineyard.png",
      //   href: "/mood-management",
      // },
      {
        title: "User Management",
        icon: "/images/dashboard/planning.png",
        href: "/user-management",
      },
      {
        title: "Vendor Management",
        icon: "/images/dashboard/kyc.png",
        href: "/vendor-management",
      },
      {
        title: "Brand Management",
        icon: "/images/dashboard/kyc.png",
        href: "/brand-management",
      },
      {
        title: "NFT Management",
        icon: "/images/dashboard/planning.png",
        href: "/nft-list",
      },
      
      {
        title: "Control",
        icon: "/images/dashboard/kyc.png",
        href: "/admin-control",
      },
      
      {
        title: "Report Management",
        icon: "/images/dashboard/kyc.png",
        href: "/report-management",
      },
      {
        title: "Notified Management",
        icon: "/images/dashboard/broadcast.png",
        href: "/notified-management",
      },
      {
        title: "Static Management",
        icon: "/images/dashboard/broadcast.png",
        href: "/static-management",
      },
      {
        title: "FAQ Management",
        icon: "/images/dashboard/ip.png",
        href: "/faq-list",
      },
    ],
  },
];


function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }
  return acc;
}
const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
    backgroundColor: "#141414",
  },
  desktopDrawer: {
    top: "76px",
    width: "250px",
    height: "calc(100% - 115px)",
    // background: "rgba(255, 255, 255, 0.40)",
    margin: "5px 10px 10px 15px",
    borderRadius: "10px",
    border: "1px solid rgba(0, 0, 0, 0.15)",
    marginTop: "21px",
    marginLeft: "13px",
    backgroundColor:"rgb(34 28 61)!important",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },
  button: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    height: "45px",
    paddingLeft: "17px",
    borderRadius: "12px",
    marginTop: "-30px",
    "&:hover": {
      color: "#F5C843",
    },
    "& svg": {
      color: "#F5C843",
      fontSize: "20px",
    },
  },
  mainSideTop:{
    // position: "relative",
    height: "calc(100% - 32px)",
    overflow: "auto",
    [theme.breakpoints.down("xs")]: {
      // height: "calc(100% - 470px)",
    },
    "& .mainSiderBarBox": {
      // height: "calc(100% - -170px)",
      // overflow: "auto",
      "& .profileBox": {
        "& .adminDp": {
          height: "150px",
          width: "150px",
          border: "4px solid #f53756",
          borderRadius: "50%",
          "& img": {
            width: "100%",
            height: "100%",
            maxHeight: "150px",
            maxWidth: "150px",
            position: "relative",
            backgroundSize: "cover !important",
            backgroundRepeat: "no-repeat !important",
            objectFit: "contain !important",
            borderRadius: "50%",
          },
        },
        "& h6": {
          color: "#fff",
          fontWeight: "600",
          margin: "10px 0px",
        },
        "& p": {
          fontWeight: 400,
          color: "#fff",
          marginBottom: "24px",
        },
      },
    },
  },
  btnBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "transparent",
    fontWeight: "400",
    fontSize: "13px",
    color: "#fff",
    marginTop: "20px",
    "& button": {
      borderRadius: "50px",
      position: "absolute",
      bottom: "10px",
    },
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
          <Box className={classes.mainSideTop}>
        <Box className="mainSiderBarBox">
          <Box className="profileBox displayColumn">
            <Box mt={3} className="adminDp">
              <img src="images/profile.png" alt="" />
            </Box>
            <Typography variant="h6">Prakash Singh</Typography>

            <Divider
              style={{
                border: "0.5px solid rgba(255, 255, 255, 0.40)",
                width: "90%",
                marginTop: "8px",
              }}
            />
          </Box>
      <PerfectScrollbar options={{ suppressScrollX: true }}>
      <Box pt={2} pb={2} style={{ margin: "10px" }}>
          {sections.map((section, i) => (
            <List
              key={`menu${i}`}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
              })}
            </List>
          ))}
        </Box>
        <Box className={classes.btnBox}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setOpen(true)}
          >
            Logout
          </Button>
        </Box>
      </PerfectScrollbar>
      </Box>
      </Box>
      <ConfirmationDialog
        open={open}
        handleClose={() => setOpen(false)}
        title={"Logout"}
        desc={"Do you want to Logout ?"}
      />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          <Box p={2}>{content}</Box>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
