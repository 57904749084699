import {
  AppBar,
  Toolbar,
  Button,
  Drawer,
  Grid,
  Box,
  Container,
  Link
} from "@material-ui/core";
import React, { useState, useEffect, useContext, useMemo } from "react";
import Logo from "./../../component/Logo";
import Scroll from "react-scroll";

const ScrollLink = Scroll.Link;

export default function TopBar() {

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      setState((prevState) => ({
        ...prevState,
        mobileView: window.innerWidth < 1080,
      }));
    };

    setResponsiveness();
    window.addEventListener("resize", setResponsiveness);

    return () => {
      window.removeEventListener("resize", setResponsiveness);
    };
  }, []);



  const femmecubatorLogo = (
    <Box>
      <Link href="/">
        <Logo className="logoImg" />
      </Link>
    </Box>
  );



  const displayMobile = () => {
    return (
      <Toolbar className={""}>
        <Box className="topbarmainBox">
          <div>{femmecubatorLogo}</div>
        </Box>
      </Toolbar>
    );
  };
  const displayDesktop = () => {
    return (
      <Container>
        <Toolbar className="topbarmainBox">
          {femmecubatorLogo}
          <Grid
            container
            item
            direction="row"
            justify="flex-end"
            alignItems="center"
            style={{ paddingLeft: "0px" }}
            className="displayEnd"
          ></Grid>
        </Toolbar>
      </Container>
    );
  };
  return (
    <>
      <AppBar
        elevation={0}
        style={{
          background: "#161032",
          // borderBottom: "1px solid #EAEAEA",
        }}
      >
        <Box>{mobileView ? displayMobile() : displayDesktop()}</Box>
      </AppBar>
    </>
  );
}
