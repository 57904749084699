import React, { useState, useEffect, useRef, useContext } from "react";
import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  Grid,
  MenuItem,
  Box,
  Container,
  Typography,
  withStyles,
  Menu,
  Avatar,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { Link, useHistory, useLocation } from "react-router-dom";
import Logo from "./../../component/Logo";
import Scroll from "react-scroll";
import MuiDialogContent from "@material-ui/core/DialogTitle";
import MuiDialog from "@material-ui/core/Dialog";
import { useWeb3React } from "@web3-react/core";
import { UserContext } from "src/context/User";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import EditIcon from "@material-ui/icons/Edit";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ConfirmationDialog from "src/component/ConfirmationDialog";
import ConnectModal from "src/component/ConnectModal";
import DisconnectModal from "src/component/DiconnectModal";
import { sortAddress } from "src/utils";

const ScrollLink = Scroll.Link;

const useStyles = makeStyles((theme) => ({
  drawerContainerText: {
    "& a": {
      color: "#b51632",
    },
    "& .nav a": {
      color: "#b51632",
    },
  },
  menuButton: {
    fontSize: "14px",
    lineHeight: "24px",
    fontWeight: "300",
    borderRadius: 0,
    minWidth: "auto",
    color: "#fff",
    height: "31px",
    padding: "0px 10px",
    letterSpacing: "1px",
    marginLeft: "10px",
    "& .nav a": {
      color: "#fff",
    },
    "@media (max-width: 900px)": {
      fontStyle: "normal",
      letterSpacing: "-0.6px",
      lineHeight: "24px",
      color: "#FFF",
      padding: "15px !important",
      height: "51px",
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
    "& .MuiMenu-list": {
      with: "193px !important",
    },
    "& a": {
      color: "#fff",
    },
    "& .ul.navigation a": {
      color: "#fff",
    },
    "&.activeMenu": {
      background: "linear-gradient(180deg, #FDA645 0%, #FF00CD 100%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      backgroundClip: "text",
      textFillColor: "transparent",
      position: "relative",
      "&:before": {
        content: "' '",
        position: "absolute",
        top: "26px",
        left: " 0px",
        width: "100%",
        height: "5px",
        background:
          "radial-gradient(50% 50% at 50% 50%, #FDA645 0%, rgba(255, 0, 205, 0) 100%)",
        [theme.breakpoints.only("xs")]: {
          display: "none",
        },
      },
    },
    "& h6": {
      fontWeight: "400",
    },
    // "&:hover": {
    //   background: "#90909838",
    // },
  },
  toolbar: {
    display: "flex",
    padding: "6px 0",
    justifyContent: "space-between",
    height: "100%",
    "@media (max-width: 900px)": {
      paddingLeft: "75px",
      paddingRight: "20px",
      height: "100%",
    },
  },

  drawerContainer: {
    padding: "20px 0px ",
    height: "100%",
    // background: '#06010D',
    backgroundColor: "#22183A",
    width: "290px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    "& a": {
      color: "#fff",
      "&:hover": {
        color: "#b51632",
        textDecoration: "none",
      },
    },
    "& .nav a": {
      color: "#b51632",
    },
  },
  drawericon: {
    color: "#000",
    position: "absolute",
    top: "0px",
    right: "0px",
    fontSize: "25px",
  },
  logoImg: {
    marginTop: "5px",
    width: "192px",
    objectFit: "contain",
    "@media (max-width: 500px)": {
      width: "155px",
    },
  },
  containerHeight: {
    height: "100%",
  },
  appmain: {
    background: "transparent !important",
    //     border: "none",
    position: "fixed",
    backdropFilter: "blur(4px) !important",
  },

  mainHeader: {
    justifyContent: "space-between",
    // padding: "0px",
  },
  wallet: {
    margin: " 0px 10px",

    "@media (max-width: 900px)": {
      marginTop: "12px",
      background: "rgba(204, 35, 75, 0.1)",
      boxShadow:
        "0px 4px 4px rgb(0 0 0 / 25%), inset 0px 0px 8px #b61733, inset 0px 0px 35px #b51632",
      borderRadius: "50px",
    },
  },

  activeBlock: {
    position: "relative",
    color: "#b51632",
    fontWeight: "bold",

    textDecorationThickness: "3px",
    "&::before": {
      top: "24px",
      width: "calc(100% - 21px)",
      height: "2px",
      content: "''",
      position: "absolute",
      background: "#ccc",
    },
  },
  nameSymbol: {
    // border: "1px solid #ccc",
    background: "#F53756",
    width: "42px",
    height: "42px",
    borderRadius: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& h4": {
      fontSize: "18px",
      fontWeight: "600",
      textTransform: "uppercase",
    },
  },
  MenuItemshover: {
    padding: "6px 10px",
    "&:hover": {
      backgroundColor: "#9c162e",
    },
  },
  flexbox: {
    display: "flex",
    alignItems: "center",
    "& h4": {
      color: "#fff",
    },
  },
}));
const StyledMenu = withStyles({
  paper: {
    marginTop: "2px",
  },
  "& ..MuiListItem-button": {
    "&:hover": {
      textDecoration: "none",
      backgroundColor: "#9c162e",
    },
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));
export default function Header() {
  const classes = useStyles();
  const user = useContext(UserContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const location = useLocation();
  const [openLogout, setOpenLogout] = useState(false);
  const { account } = useWeb3React();
  const [openDisconnect, setOpenDisConnect] = useState(false);

  const handlescroll = () => {
    if (location.pathname === "/") {
      window.scrollTo({ top: 2000, behavior: "smooth" });
    } else {
      history.push("/");
    }
  };
  const handlescrollHolders = () => {
    if (location.pathname === "/") {
      window.scrollTo({ top: 1000, behavior: "smooth" });
    } else {
      history.push("/");
    }
  };
  const handlescrollTop = () => {
    if (location.pathname === "/") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      history.push("/");
    }
  };
  const handlescroll3 = () => {
    if (location.pathname === "/") {
      window.scrollTo({ top: 3900, behavior: "smooth" });
    } else {
      history.push("/");
    }
  };
  const handlescrollTeam = () => {
    if (location.pathname === "/") {
      window.scrollTo({ top: 5000, behavior: "smooth" });
    } else {
      history.push("/");
    }
  };

  const {
    flexbox,
    menuButton,
    toolbar,
    drawerContainer,
    drawerContainerText,
    drawericon,
    containerHeight,
    appmain,
    mainHeader,
    wallet,
    activeBlock,
    nameSymbol,
    MenuItemshover,
  } = useStyles();
  const history = useHistory();
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });
  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1224
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  const [activeSet, setActiveSet] = useState("Home");
  useEffect(() => {
    if (location.pathname === "/") {
      setActiveSet("Home");
    } else if (location.pathname === "/marketplace") {
      setActiveSet("marketplace");
    } else if (location.pathname === "/brands") {
      setActiveSet("brands");
    } else if (location.pathname === "/infrastructure") {
      setActiveSet("Dashboard");
    } else if (location.pathname === "/transaction-management") {
      setActiveSet("class");
    } else if (location.pathname === "/institute") {
      setActiveSet("institute");
    } else if (location.pathname === "/plot") {
      setActiveSet("plot");
    } else if (location.pathname === "/profile") {
      setActiveSet("profile");
    } else if (location.pathname === "/blog-list") {
      setActiveSet("Blog");
    } else {
      setActiveSet("");
    }
  }, [location]);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  function nameSybmolCreator(first, last) {
    const firstName = first?.slice(0, 1);
    const lastName = last?.slice(0, 1);
    const shortForm = `${firstName}${lastName}`;
    return shortForm;
  }
  const displayDesktop = () => {
    return (
      <Container maxWidth="lg" className="p-0">
        <Toolbar className={toolbar}>
          {femmecubatorLogo}
          <Grid
            container
            item
            direction="row"
            justify="flex-end"
            alignItems="center"
            style={{ paddingLeft: "0px" }}
          >
            <nav>
              <ul className="navigation">
                <li>
                  <MenuItem
                    exact
                    activeClassName="activeMenu"
                    className={[
                      menuButton,
                      activeSet === "Home" ? activeBlock : null,
                    ]}
                    onClick={() => {
                      handlescrollTop();
                      setActiveSet("Home");
                    }}
                  >
                    Home
                  </MenuItem>
                </li>
                {user?.userData?.userType === "ADMIN" &&
                  window.localStorage.getItem("token") && (
                    <li>
                      <MenuItem
                        className={[
                          menuButton,
                          activeSet === "Dashboard" ? activeBlock : null,
                        ]}
                        onClick={() => history.push("/transaction-management")}
                      >
                        Dashboard{" "}
                      </MenuItem>{" "}
                    </li>
                  )}
                <li>
                  <MenuItem
                    className={[
                      menuButton,
                      activeSet === "marketplace" ? activeBlock : null,
                    ]}
                    onClick={() => history.push("/marketplace")}
                  >
                    Marketplace
                  </MenuItem>
                </li>
                <li>
                  <MenuItem
                    className={[
                      menuButton,
                      activeSet === "brands" ? activeBlock : null,
                    ]}
                    onClick={() => history.push("/brands")}
                  >
                    Brands
                  </MenuItem>
                </li>

                {window.localStorage.getItem("token") &&
                window.localStorage.getItem("token") ? (
                  <IconButton
                    className={[menuButton]}
                    aria-label="delete"
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                    // className={menuMobile1}

                    size="small"
                  >
                    {user?.userData?.firstName && user?.userData?.lastName ? (
                      <Box className={nameSymbol}>
                        <Typography variant="h4">
                          {user?.userData?.firstName &&
                            user?.userData?.lastName &&
                            nameSybmolCreator(
                              user?.userData?.firstName,
                              user?.userData?.lastName
                            )}
                        </Typography>
                      </Box>
                    ) : (
                      <Avatar
                        style={{
                          width: "42px",
                          height: "42pxs",

                          backgroundColor: "#F53756",
                        }}
                      />
                    )}
                  </IconButton>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    className={wallet}
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={() => history.push("/login")}
                  >
                    Login
                  </Button>
                )}
                {user?.userLoggedIn && (
                  <li style={{ marginLeft: "6px" }}>
                    {!account && (
                      <Button
                        variant="contained"
                        color="primary"
                        className={wallet}
                        onClick={() => user.setIsOpenConnectWallet(true)}
                      >
                        Connect Wallet
                      </Button>
                    )}
                    {account && (
                      <Button
                        variant="contained"
                        color="primary"
                        className={wallet}
                        onClick={() => setOpenDisConnect(true)}
                      >
                        {`Disconnect ${sortAddress(account)}`}
                      </Button>
                    )}
                  </li>
                )}
                <IconButton
                  style={{ padding: "0px", background: "transparent" }}
                  onClick={()=>history.push("/search-data")}
                >
                  <img
                    src="/images/searchbutton.png"
                    alt="search"
                    width="40px"
                  />
                </IconButton>
              </ul>
            </nav>
          </Grid>
          {window.localStorage.getItem("token") && (
            <Box>
              <StyledMenu
                id="simple-menu"
                disableScrollLock={true}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                className="poperForMenu"
              >
                <MenuItem
                  className={MenuItemshover}
                  onClick={() => {
                    handleClose();
                    history.push("/author-profile");
                  }}
                >
                  <Box className={flexbox}>
                    <AccountBoxIcon
                      style={{ marginRight: "8px", color: "#fff" }}
                    />
                    <Typography
                      variant="h4"
                      style={{ fontSize: "13px" }}
                      className="profileText"
                    >
                      Profile
                    </Typography>
                  </Box>
                </MenuItem>

                <MenuItem
                  className={MenuItemshover}
                  onClick={() => {
                    handleClose();
                    history.push("/update-profile");
                  }}
                >
                  <Box className={flexbox}>
                    <EditIcon style={{ marginRight: "8px", color: "#fff" }} />
                    <Typography
                      variant="h4"
                      style={{ fontSize: "13px" }}
                      className="profileText"
                    >
                      Edit Profile
                    </Typography>
                  </Box>
                </MenuItem>
                <MenuItem
                  className={MenuItemshover}
                  onClick={() => {
                    handleClose();
                    setOpenLogout(true);
                  }}
                >
                  <Box className={flexbox}>
                    <ExitToAppIcon
                      style={{ marginRight: "8px", color: "#fff" }}
                    />
                    <Typography
                      variant="h4"
                      style={{ fontSize: "13px" }}
                      className="profileText"
                      w
                    >
                      Logout
                    </Typography>
                  </Box>
                </MenuItem>
              </StyledMenu>
            </Box>
          )}
        </Toolbar>
      </Container>
    );
  };
  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar className={mainHeader}>
        <Drawer
          {...{
            anchor: "right",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer}>
            <Box className="drawer2"></Box>
            {getDrawerChoices(handleDrawerClose)}
            <Box width="100%">
              {!window.localStorage.getItem("token") && (
                <>
                  <Box mt={1}>
                    <Button
                      variant="contained"
                      color="primary"
                      className={wallet}
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={() => history.push("/login")}
                    >
                      Login
                    </Button>
                  </Box>
                </>
              )}

              {window.localStorage.getItem("token") && (
                <Box mt={1}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={wallet}
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={() => setOpenLogout(true)}
                  >
                    Logout
                  </Button>
                </Box>
              )}
              {user?.userLoggedIn && (
                <>
                  <Box mt={2}>
                    {!account && (
                      <Button
                        variant="contained"
                        color="primary"
                        className={wallet}
                        onClick={() => user.setIsOpenConnectWallet(true)}
                      >
                        Connect Wallet
                      </Button>
                    )}
                    {account && (
                      <Button
                        variant="contained"
                        color="primary"
                        className={wallet}
                        onClick={() => setOpenDisConnect(true)}
                      >
                        {`Disconnect ${sortAddress(account)}`}
                      </Button>
                    )}
                  </Box>
                </>
              )}
            </Box>
          </div>
        </Drawer>
        <Grid
          container
          style={{
            alignItems: "center",
          }}
        >
          <Grid item xs={6} sm={8} md={10}>
            {" "}
            <div>{femmecubatorLogo}</div>
          </Grid>
          <Grid item xs={6} sm={4} md={2}>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <IconButton
                className={drawericon}
                {...{
                  edge: "start",
                  color: "inherit",
                  "aria-label": "menu",
                  "aria-haspopup": "true",
                  onClick: handleDrawerOpen,
                }}
              >
                <MenuIcon
                  width="60px"
                  height="60px"
                  style={{ color: "#7C162E", fontSize: "30px" }}
                />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </Toolbar>
    );
  };
  const getDrawerChoices = (handleDrawerClose) => {
    return (
      <>
        <nav className={drawerContainerText}>
          <input type="checkbox" id="check" />
          <label clasnames="logo">
            <img
              src="/images/logo.svg"
              alt="Logo"
              width="100%"
              style={{ width: "100%", maxWidth: "150px" }}
            />
          </label>
          <ul className="navigation">
            <ScrollLink
              onClick={() => {
                handlescrollTop();
                handleDrawerClose(); //callbackFunction
              }}
              className="hovertext"
              smooth={true}
              duration={500}
              to="Home"
              style={{ cursor: "pointer" }}
            >
              <Typography
                variant="h6"
                paragraph
                className={`${classes.typosec} hovertext`}
              >
                Home
              </Typography>
            </ScrollLink>

            {user?.userData?.userType === "ADMIN" &&
              window.localStorage.getItem("token") && (
                <ScrollLink
                  onClick={() => history.push("/transaction-management")}
                  className="hovertext"
                  smooth={true}
                  duration={500}
                  style={{ cursor: "pointer" }}
                >
                  <Typography
                    variant="h6"
                    paragraph
                    className={`${classes.typosec} hovertext`}
                  >
                    Dashboard
                  </Typography>
                </ScrollLink>
              )}

            <ScrollLink
              onClick={() => history.push("/marketplace")}
              className="hovertext"
              smooth={true}
              duration={500}
              style={{ cursor: "pointer" }}
            >
              <Typography
                variant="h6"
                paragraph
                className={`${classes.typosec} hovertext`}
              >
                Marketplace
              </Typography>
            </ScrollLink>

            <ScrollLink
              onClick={() => history.push("/brands")}
              className="hovertext"
              smooth={true}
              duration={500}
              style={{ cursor: "pointer" }}
            >
              <Typography
                variant="h6"
                paragraph
                className={`${classes.typosec} hovertext`}
              >
                Brands
              </Typography>
            </ScrollLink>

            {window.localStorage.getItem("token") && (
              <ScrollLink
                onClick={() => history.push("/author-profile")}
                className="hovertext"
                smooth={true}
                duration={500}
                style={{ cursor: "pointer" }}
              >
                <Typography
                  variant="h6"
                  paragraph
                  className={`${classes.typosec} hovertext`}
                >
                  Profile
                </Typography>
              </ScrollLink>
            )}
          </ul>
        </nav>
      </>
    );
  };
  const femmecubatorLogo = (
    <Box onClick={handlescrollTop}>
      <Link to="/">
        <Logo className={classes.logoImg} />
      </Link>
    </Box>
  );

  return (
    <>
      <AppBar
        position={history.location.pathname !== "/" ? "relative" : "absolute"}
        elevation={0}
        className={appmain}
      >
        <Box
          maxWidth={history.location.pathname !== "/" ? "lg" : "fixed"}
          className={containerHeight}
        >
          {mobileView ? displayMobile() : displayDesktop()}
        </Box>
      </AppBar>
      <ConfirmationDialog
        open={openLogout}
        handleClose={() => setOpenLogout(false)}
        title={"Logout"}
        desc={"Do you want to Logout ?"}
      />
      <ConnectModal
        user={user}
        account={account}
        setOpen={() => user.setIsOpenConnectWallet(false)}
        open={user.isOpenConnectWallet}
      />
      <DisconnectModal
        user={user}
        open={openDisconnect}
        onClose={() => setOpenDisConnect(false)}
      />
    </>
  );
}
