import React, { createContext, useEffect, useState } from "react";
import { ACTIVE_NETWORK, NetworkDetails } from "src/constants";
import { useWeb3React } from "@web3-react/core";
import { SUPPORTED_WALLETS } from "src/connectors";
import { getWeb3Obj } from "src/utils";
import axios from "axios";

import {
  getAPIHandle,
  getPerticularData,
  postAPIHandle,
  postAPIHandleConnectWallet,
} from "src/ApiConfig/service";
import { Redirect, useHistory } from "react-router-dom";
import Apiconfigs from "src/ApiConfig/ApiConfig";
import toast from "react-hot-toast";
export const UserContext = createContext();

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("creatturAccessToken", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem("creatturAccessToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

function checkLogin(token) {
  const accessToken = window.localStorage.getItem("token")
    ? window.localStorage.getItem("token")
    : token;
  return accessToken ? true : false;
}

let logoutTimeout;

export default function AuthProvider(props) {
  const { activate, account, chainId, deactivate } = useWeb3React();
  const [isOpenConnectWallet, setIsOpenConnectWallet] = useState(false);
  const [isLogin, setIsLogin] = useState(checkLogin());
  const [categoryList, setCategoryList] = useState("");
  const [userData, setUserData] = useState({});
  const [albumList, setAlbumList] = useState([]);
  const [moodList, setMoodList] = useState([]);
  const [yourWalletBalance, setYourWalletBalance] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  //-----*WEB3-Connection-Establisher*-----//
  const connectWalletHandler = (data) => {
    try {
      const connector = data?.connector;
      localStorage.setItem("walletName", data?.name);
      sessionStorage.removeItem("walletName");
      if (connector && connector.walletConnectProvider?.wc?.uri) {
        connector.walletConnectProvider = undefined;
      }
      activate(connector, undefined, true).catch((error) => {
        if (error) {
          localStorage.removeItem("walletName");
          console.log("error", error);
        }
      });
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  //-----*WEB3-Network-Change-Request*-----//
  const swichNetworkHandler = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x" + ACTIVE_NETWORK.toString(16) }],
      });
    } catch (error) {
      console.log("ERROR", error);
      if (error.code === 4902) {
        addNetworkHandler();
      }
    }
  };

  //-----*WEB3-Network-Add-Request*-----//
  const addNetworkHandler = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: NetworkDetails,
      });
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  //-----*WEB3-Connection-Disable*-----//
  const disconnectWalletHandler = async () => {
    try {
      deactivate();
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("userType");
      window.localStorage.removeItem("walletName");
      window.location = "/";
    } catch (error) {
      console.log(error);
    }
  };

  const handleLogout = () => {
    setIsLogin(false);
    setSession(null);
    disconnectWalletHandler();
    clearTimeout(logoutTimeout);
  };

  //-----*APIs-START*-----//
  const connectWalletAPIHandler = async (userAddress) => {
    try {
      const response = await postAPIHandleConnectWallet("connectWallet", {
        walletAddress: userAddress,
      });
      if (response) {
        // window.localStorage.setItem("token", response.token);
        // getProfileAPIHandler(response.token);

      }
    } catch (error) {

      console.log(error);
      
    }
  };

  const handleProfileResponse = (response) => {
    if (response.data.responseCode === 200) {
      setUserData(response.data.result);
    } else {
      handleLogout();
    }
  };

  const handleProfileError = () => {
    console.log("Profile API call failed or timed out");
    handleLogout();
  };

  const getProfileAPIHandler = async (token) => {
    try {
      setIsLoading(true);
      getGenreListAPIHandler();
      getMoodListAPIHandler();
      const response = await axios({
        method: "GET",
        url: Apiconfigs.getProfile,
        headers: {
          token: window.localStorage.getItem("token"),
        },
      });
      console.log("getProfile=========", response);

      if (response.data.responseCode === 200) {
        handleProfileResponse(response);
      } else {
        handleProfileError();
      }
    } catch (error) {
      console.log(error);
      handleProfileError();
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("token")) getProfileAPIHandler();
  }, [localStorage.getItem("token")]);

  const getGenreListAPIHandler = async () => {
    try {
      const response = await postAPIHandle("categoryList");
      setCategoryList(response.docs);
    } catch (error) {
      console.log(error);
    }
  };

  const getMoodListAPIHandler = async () => {
    try {
      const response = await postAPIHandle("moodList");
      setMoodList(response.docs);
    } catch (error) {
      console.log(error);
    }
  };

  const getMyCollectionListAPI = async () => {
    try {
      const response = await getPerticularData("myCollectionList");
      if (response) {
        setAlbumList(response.docs);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getMyCollectionListAPI();
  }, []);

  // account balance -----//
  const getUserbalce = async () => {
    const web3 = await getWeb3Obj();
    const balance = await web3.eth.getBalance(account);
    const balanceImETH = await web3.utils.fromWei(balance);
    setYourWalletBalance(parseFloat(balanceImETH).toFixed(2));
  };

  useEffect(() => {
    if (account) {
      getUserbalce();
    }
  }, [account]);

  //-----*APPLICATION-DATA-LAYER-OBJECT*-----//
  let data = {
    userLoggedIn: isLogin,
    userData,
    categoryList,
    isOpenConnectWallet,
    albumList,
    yourWalletBalance,
    isLoading,
    moodList,
    userLogIn: (type, data) => {
      setSession(data);
      setIsLogin(type);
      // Set auto-logout for 24 hours
      logoutTimeout = setTimeout(() => {
        handleLogout();
      }, 24 * 60 * 60 * 1000); // 24 hours
    },
    setIsLogin,
    updateUser: (account) => {
      setSession(account);
    },
    getMyCollectionListAPI: () => getMyCollectionListAPI(),
    connectWallet: (item) => connectWalletHandler(item),
    updateProfileHandler: () =>
      getProfileAPIHandler(window.localStorage.getItem("token")),
    disconnectWallet: () => {
      disconnectWalletHandler();
    },
    checkLogin: (token) => {
      checkLogin(token);
    },
    setIsOpenConnectWallet: (item) => setIsOpenConnectWallet(item),
  };

  useEffect(() => {
    data.updateUser(account);
    if (account) {
      setIsOpenConnectWallet(false);
      connectWalletAPIHandler(account);
    }
  }, [account]);

  useEffect(() => {
    if (account && chainId) {
      if (chainId != ACTIVE_NETWORK) {
        window.scrollTo(0, 0);
        if (window.ethereum) {
          swichNetworkHandler();
        }
      }
    }
  }, [chainId, account]);

  useEffect(() => {
    if (localStorage.getItem("walletName")) {
      const selectectWalletDetails = SUPPORTED_WALLETS.filter((data) => {
        return data?.data?.name == localStorage.getItem("walletName");
      });
      if (selectectWalletDetails.length > 0) {
        connectWalletHandler(selectectWalletDetails[0].data);
      }
    }
  }, [localStorage.getItem("walletName")]);

  return (
    <UserContext.Provider value={data}>{props.children}</UserContext.Provider>
  );
}
