import React from "react";
import {
  Box,
  Container,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  makeStyles,
  Typography,
} from "@material-ui/core";
import {
  FaTwitter,
  FaTelegramPlane,
  FaWhatsapp,
  FaInstagram,
} from "react-icons/fa";

const useStyles = makeStyles((theme) => ({
  footerSection: {
    position: "relative",
    padding: "50px 0px 0",
    zIndex: "2",
    overflow: " hidden",
    background: "rgba(255, 255, 255, 0.02)",
    background: "url(/images/footer_banner.png) no-repeat center/cover",
    color: "#fff",
    padding: "20px 0 0",
    backgroundRepeat: "no-repeat",
    "& p": {
      color: "rgba(255, 255, 255, 0.87)",
    },
    "& .copy": {
      borderTop: "1px solid #d0d0d017",
      padding: "10px 0",
      textAlign: "center",
      fontWeight: 300,
      fontSize: "12px",
      "& p": {
        color: "rgba(255, 255, 255, 0.87)",
        fontWeight: "300",
        fontSize: "13px",
      },
    },
    "& svg": {
      color: "#f53756 !important",
      fontSize: "27px !important",
      cursor: "pointer",
    },
    "& h6": {
      [theme.breakpoints.down("sm")]: {
        marginTop: "30px",
      },
      [theme.breakpoints.down("xs")]: {
        marginTop: "10px",
      },
    },
    "& .MuiListItem-gutters":{
      paddingLeft:"0px",
    },
    "& a": {
      fontSize: "14px !important",
    },
  },
  footerContentBox: {
    maxWidth: "260px",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
    },
  },
  iconbtn: {
    display: "flex",
    alignItems: "center",
    "& .MuiIconButton-root": {
      background: "transparent",
      marginRight: "15px",
      padding: "0px",
      [theme.breakpoints.down("xs")]: {
        marginBottom: "10px",
      },
    },
  },
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <Box className={classes.footerSection}>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={4}>
            <Box className={classes.footerContentBox}>
              <Box mb={2}>
                {" "}
                <Link href="/">
                  <img
                    src="/images/logo.svg"
                    alt=""
                    style={{ width: "152px" }}
                  />{" "}
                  <br />
                </Link>
              </Box>
              <Typography
                variant="body2"
                style={{ color: "#F53756", maxWidth: "201px",fontWeight:"700" }}
              >
                The NFT Marketplace for Fine Wines
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sm={3} md={3}>
            <Typography
              variant="body2"
              style={{ color: "#F53756", fontWeight: "700",fontSize:"16px" }}
              color="primary"
            >
              Platform
            </Typography>
            <List>
              <ListItem href="/brands" component={Link}>
                Brands
              </ListItem>

              <ListItem href="/marketplace" component={Link}>
                Marketplace
              </ListItem>

              <ListItem href="/faq" component={Link}>
                FAQ
              </ListItem>
            </List>
          </Grid>

          <Grid item xs={6} sm={3} md={3}>
            <Typography
              variant="body2"
              style={{ color: "#F53756", fontWeight: "700",fontSize:"16px" }}
              color="primary"
            >
              Contact Us
            </Typography>
            <List>
              <ListItem>
                <Link href="mailto:Vintage@mailinator.com">
                  Vintage@mailinator.com
                </Link>
              </ListItem>

              <ListItem style={{ cursor: "pointer" }}>
                <Link>9109-9806432790</Link>
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={6} sm={3} md={2}>
            <Typography
              variant="body2"
              style={{ color: "#F53756", fontWeight: "700",fontSize:"16px" }}
              color="primary"
            >
              Follow Us:
            </Typography>

            <Box className="iconbtn" mt={2}>
              <IconButton target="_blank" href="https://www.whatsapp.com/" style={{padding:"0px"}}>
                <Link>
                  <FaWhatsapp />
                </Link>
              </IconButton>
              <IconButton style={{padding:"0px",marginLeft:"16px"}}>
                <Link target="_blank" href="https://twitter.com">
                  <FaTwitter />
                </Link>
              </IconButton>
              <IconButton style={{padding:"0px",marginLeft:"16px"}}>
                <Link target="_blank" href="https://telegram.org/">
                  <FaTelegramPlane />
                </Link>
              </IconButton>
              <IconButton style={{padding:"0px",marginLeft:"16px"}}>
                <Link target="_blank" href="https://intagram.com/">
                  <FaInstagram />
                </Link>
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Box className="copy" mt={1}>
        <Container >
          <Box
            alignItems="center"
            position="relative"
            flexWrap="wrap"
            display="flex"
            justifyContent="space-between"
          >
            <Typography variant="body2">© 2023 Vintage</Typography>
            <Box className="displayStart">
              <List className="displayStart">
                <ListItem
                  href="/about-us"
                  target="_blank"
                  component={Link}
                  style={{ whiteSpace: "pre" }}
                >
                  About Us
                </ListItem>
                <ListItem
                  href="/privacy-policy"
                  target="_blank"
                  component={Link}
                  style={{ whiteSpace: "pre" }}
                >
                  Privacy Policy
                </ListItem>
                <ListItem
                  href="/terms-conditions"
                  target="_blank"
                  component={Link}
                  style={{ whiteSpace: "pre" }}
                >
                  Terms & Conditions
                </ListItem>
              </List>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default Footer;
