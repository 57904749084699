import _ from "lodash";
import { colors, createMuiTheme, responsiveFontSizes } from "@material-ui/core";
import typography from "./typography";

const baseOptions = {
  typography,
  overrides: {
    MuiPagination: {
      ul: {
        margin: "0",
        display: "flex",
        padding: "0",
        listStyle: "none",
        alignItems: "center",
        flexWrap: "inherit",
      },
    },
    PrivateValueLabel: {
      circle: {
        backgroundColor: "#e71833",
        overflow: "hidden",
        height: "40px !important",
        width: "40px !important",
      },
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: "0px",
      },
    },
    MuiMenu: {
      list: {
        outline: "0",
        background: "#231a39",
        color: "#ffffffb0",
        fontSize: "13px",
        fontWeight: "300",
        fontFamily: "'Sora' !important",
      },
    },
    MuiCheckbox: {
      root: {
        color: "rgba(255, 255, 255, 0.1)",
      },
    },
    MuiDialog: {
      paper: {
        border: "1px solid #2C235A",
        margin: "32px",
        position: "relative",
        background: "rgba(137, 113, 253, 0.10)",
        overflowY: "auto",
        backdropFilter: "blur(100px)",
        borderRadius: "10px",
        width: "100%",
        // maxWidth: "500px !important",
      },
    },
    MuiDialogContent: {
      root: {
        flex: "1 1 auto",
        padding: "8px 18px",
      },
    },

    MuiDropzoneArea: {
      root: {
        borderRadius: "20px !important",
        borderColor: "rgba(255, 255, 255, 0.40) !important",
        backgroundColor: "transparent !important",
        minHeight: "200px !important",
        "&:hover": {
          color: "#ffffff !important",
          borderColor: "rgba(255, 255, 255, 0.40) !important",
          backgroundOrigin: "border-box",
          backgroundColor: "rgba(255, 255, 255, 0.05) !important",
        },
      },
      icon:{
        color:"#fff",
      }
    },
    MuiAutocomplete: {
        option: {
          color: "#fff",
          fontSize: "14px !important",
          fontWeight: "400",
          letterSpacing: "0px",
          textAlign: "left",
          background: "transparent",
          // height: "40px",
        },
        input: {
          width: "0",
          // minWidth: "30px",
          color: "rgba(255, 255, 255, 0.87)",
          fontSize: "12px !important",
          fontWeight: "400",
        },
        popupIndicator: {
          height: "30px !important",
          width: "40px !important",
        },
    },
    MuiDateCalendar: {
      root: {
        "& .MuiPickersArrowSwitcher-button": {
          backgroundColor: "transparent !important",
          color: "#000 !important",
        },
        "& .MuiPickersCalendarHeader-switchViewButton": {
          backgroundColor: "transparent !important",
          color: "#000 !important",
          marginLeft: "-15px !important",
        },
        "& MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected)": {
          color: "#fff !important",
          backgroundColor: "#7A69FE !important",
          border: "none !important",
          borderRadius: "10px !important",
        },
        "& .Mui-selected": {
          backgroundColor: "#201738 !important",
          color: "#fff !important",
          border: "none !important",
          // borderRadius: "10px !important",
        },
        "& .MuiPickersCalendarHeader-root": {
          paddingLeft: "30px",
        },
        "& .MuiDayCalendar-slideTransition": {
          minHeight: "210px !important",
        },
        "& .MuiPickersCalendarHeader-labelContainer": {
          fontSize: "15px",
        },
      },
    },
    MuiPaper: {
      outlined: {
        padding: "20px",
        width: "100%",
      },
      elevation1: {
        border: "1px solid rgba(255, 255, 255, 0.04)",
        background: "#161032",
        borderRadius: "10px",
        padding: "20px",
      },
      elevation2: {
        border: "1px solid rgba(255, 255, 255, 0.04)",
        background: "rgba(69, 46, 84, 0.25)",
        borderRadius: "10px",
        padding: "20px",
      },
      elevation3: {
        background: "rgba(255, 255, 255, 0.40)",
        borderRadius: "10px",
        border: "1px solid rgba(0, 0, 0, 0.15)",
        padding: "30px",
        boxShadow: "none",
        backdropFilter: "blur(20px)",
      },
    },
    MuiButton: {
      root: {
        textTransform: "capitalize",
        color: "#ffffff",
      },
      contained: {
        borderRadius: "12px",
        background: "linear-gradient(165deg, #FF9AAB -64.52%, #F53756 61.26%)",
        color: "#ffffff",
        padding: "9px 30px",
      },
      outlinedSizeSmall: {
        padding: "7px 18px",
        fontSize: "14px",
        lineHeight: "21px",
        fontWeight: "500",
      },
      containedSecondary: {
        backgroundColor: "rgba(255, 255, 255, 0.04)",
        padding: "10px 30px",
        filter: "drop-shadow(0px 13px 27px rgba(0, 0, 0, 0.25))",
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "21px",
        color: "#ffffff",
        borderRadius: "12px",
        color: "#fff",
        border: "0.5px solid #F43755 !important",
        boxShadow: "none",
        background: "transparent",
        textTransform: "capitalize",
        "&:hover": {
          color: "#ffffff",
          background:
            "linear-gradient(165deg, #FF9AAB -64.52%, #F53756 61.26%)",
        },
      },
      containedPrimary: {
        color: "#fff",
        filter: "drop-shadow(0px 13px 27px rgba(0, 0, 0, 0.25))",
        padding: "10px 30px",
        border: "0.5px solid transparent !important",
        fontSize: "14px",
        borderRadius: "12px",
        background: "linear-gradient(165deg, #FF9AAB -64.52%, #F53756 61.26%)",
        fontWeight: "500",
        lineHeight: "21px",
        backgroundColor: "#898989",
        textTransform: "capitalize",
        whiteSpace: "pre",
        "&:hover": {
          color: "#fff",
          border: "0.5px solid #F43755 !important",
          boxShadow: "none",
          background: "transparent",
        },
      },
      outlinedPrimary: {
        color: "#F43755",
        border: "0.5px solid #F43755 !important",
        padding: "7px 28px",
        fontSize: "14px",
        background: "transparent",
        borderRadius: "12px",
        fontWeight: "500",
        textTransform: "capitalize",
        whiteSpace: "pre",
        "&:hover": {
          background:
            "linear-gradient(165deg, #FF9AAB -64.52%, #F53756 61.26%)",
          color: "#fff",
        },
      },
    },
    MuiCollapse: {
      wrapperInner: {
        background: "transparent",
        paddingTop: "15px",
      },
    },

    typography: {
      root: {
        color: "#ffffff",
      },
    },

    MuiOutlinedInput: {
      input: {
        position: "relative",
        fontSize: "14px",
        fontWeight: "300",
        color: "#fff",
        padding: "14px 14px",
        borderRadius: "10px",
        "&.Mui-disabled": {
          WebkitTextFillColor: "#c0b9b9 !important",
        },
      },

      root: {
        borderRadius: "10px",
        color: "#fff",
      },
      notchedOutline: {
        border: "1px solid rgba(255, 255, 255, 0.40) !important",
        "&:hover": {
          border: "1px solid #fff !important",
        },
      },
    },
    MuiInputBase: {
      input: {
        fontSize: 16,
        color: "#000",
        padding: "0px 0 0px",
      },
    },
    MuiFilledInput: {
      root: {
        position: "relative",
        transition: "background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
        backgroundColor: "#2c2c2c",
        borderRadius: "10px !important",
        "&:hover": {
          backgroundColor: "transparent",
        },
      },
      input: {
        backgroundColor: "#2C2C2C",
        borderRadius: "10px",
        padding: "0px",
        color: "#E7DDCE",

        "&:-webkit-autofill": {
          borderRadius: "10px !important",
          WebkitBackgroundClip: "text !important",
          WebkitTextFillColor: "#fff !important",
          WebkitBoxShadow: "0 0 0 1000px #2C2C2C inset",
          marginRight: "10px",
          textOverflow: "ellipsis !important",
        },
      },

      inputMultiline: {
        padding: "14px 10px",
      },

      multiline: {
        padding: "0px",
      },
      underline: {
        "&:hover": {
          "&::before": {
            borderBottom: "none",
          },
        },
        "&::before": {
          borderBottom: "none",
        },
        "&::after": {
          borderBottom: "none",
        },
      },
    },

    MuiTableCell: {
      root: {
        fontWeight: "400",
        fontFamily: "Arimo",
      },
      head: {
        padding: "15px 5px",
        whiteSpace: "pre",
        color: "#E7DDCE",
      },
      body: {
        color: "#E7DDCE",
        borderBottom: "none",
        whiteSpace: "pre",
        padding: "4px",
      },
    },
  
    MuiAccordionDetails: {
      root: {
        background: "#1D1D1D",
      },
    },
    MuiAccordionSummary: {
      root: {
        background: "rgb(83 80 74)",
        fontFamily: "Nico Moji",
        padding: "6px 16px",
        background: "rgba(0, 0, 0, 0.4)",
        backdropFilter: "blur(30px)",
        borderRadius: "10px",
        "& .Mui-expanded": {
          minHeight: "0px !important",
        },
      },
    },
    MuiPaginationItem: {
      root: {
        color: "#ffffff",
      },
    },

    MuiAccordion: {
      root: {
        color: "#ffffff",
      },
    },
  },
};

const themesOptions = {
  typography: {
    fontWeight: 400,
  },
  palette: {
    type: "light",
    action: {
      primary: "#20509e",
    },
    background: {
      default: "#FBFBFD",
      dark: "#f3f7f9",
      paper: colors.common.white,
    },
    primary: {
      main: "#fff",
      dark: "#de0d0d",
      light: "#de0d0d",
    },
    secondary: {
      main: "rgba(255, 255, 255, 0.87)",
    },
    warning: {
      main: "#ffae33",
      dark: "#ffae33",
      light: "#fff1dc",
    },
    success: {
      main: "#54e18c",
      dark: "#54e18c",
      light: "#e2faec",
    },
    error: {
      main: "#ff7d68",
      dark: "#ff7d68",
      light: "#ffe9e6",
    },
    text: {
      main: "#080031",
      secondary: "rgba(255, 255, 255, 0.87)",
    },
    common: {
      black: "#222222",
    },
  },
};

export const createTheme = (config = {}) => {
  let theme = createMuiTheme(_.merge({}, baseOptions, themesOptions));

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
